import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { SEO, Image } from '../components/UI'
import {
  InternalHeader,
  InternalContent,
  InternalTestimonial,
  InternalWorkshopNoTab,
  HealingHerbs
} from '../components/Internal'
import { WHATSAPPLINK } from '../constants/routes'
import IconFlower from '../images/common/iconFlower.svg'
import styles from './aprenda-a-usar-florais-de-bach.module.css'
export default function Page() {
  const { folder, game, floral, book } = useStaticQuery(
    graphql`
      query AprendaUsar {
        folder: file(relativePath: { eq: "courses/thumb-pasta-curso.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        game: file(relativePath: { eq: "courses/thumb-jogo-40-cartoes.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        floral: file(relativePath: { eq: "courses/thumb-floral-bach.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        book: file(relativePath: { eq: "courses/thumb-livro.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <>
      <SEO title='Aprenda a usar Florais de Bach' />
      <InternalHeader withType>
        <small>Certificação Internacional HealingHerbs</small>
        <h1>
          Aprenda a Usar
          <br />
          <strong>Florais de Bach</strong>
        </h1>
      </InternalHeader>
      <section className={styles.container}>
        <div className={styles.content}>
          <InternalContent className={styles.terapyContent}>
            <h2>
              Sobre o <strong>Curso</strong>
            </h2>
            <p>
              Nosso propósito é levar ao grupo o entendimento dos estados emocionais classificados por Bach e trazer um
              profundo conhecimento sobre a atuação das essências nas emoções humanas e no resgate dos potenciais
              energéticos da Alma, auxiliando no desbloqueio dos padrões que limitam nossa evolução. Ao final do curso o
              aluno deverá ser capaz de associar uma das emoções estudadas a uma da essência dos Florais de Bach.
            </p>
            <h2>Objetivo</h2>
            <p>
              Preparar o aluno para trabalhar com as essências florais com base nos tipos de personalidade listados pelo
              médico inglês, Dr. Edward Bach.
            </p>
            <h2>
              O que você <strong>vai aprender</strong>
            </h2>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Vida e Obra Dr. Edward Bach
              </dt>
              <dd>breve histórico sobre a vida do Dr. Bach com apresentação da filosofia e obra do médico inglês</dd>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Cura-te a Ti Mesmo
              </dt>
              <dd>
                a enfermidade como cura da Alma, o processo de cura com essências florais e a Trindade de Bach (Alma, Eu
                Superior e Eu Inferior)
              </dd>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />O Princípio de atuação do Floral
              </dt>
              <dd>como as essências vibracionais atuam no corpo emocional, a ressonância e a frequência energética</dd>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Assinatura das Plantas
              </dt>
              <dd>o gestual da planta ilustrando o estado emocional da pessoa em desequilíbrio</dd>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Método Solar e Fervura
              </dt>
              <dd>entendo a alquimia da essência floral e seus atributos</dd>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Como utilizar o floral emergencial e as 38 Essências
              </dt>
              <dd>
                os 38 tipos de personalidade listados por Bach explorados pelo estudo das suas emoções, comportamento e
                linguagem
              </dd>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A diferenciação no diagnóstico
              </dt>
              <dd>exercícios e estudos de caso dirigidos</dd>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />O Resgate da Alma com os Florais de Bach
              </dt>
              <dd>
                a atuação da essência à nível de Alma e o resgate energético da Alma com o desbloqueio dos padrões que
                limitam sua evolução
              </dd>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A criança e os Florais de Bach
              </dt>
              <dd>discussão sobre a ação das 38 essências na emoção e comportamento infantil</dd>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />O Atendimento Terapêutico com Florais
              </dt>
              <dd>
                anamnese, erros e acertos do terapeuta, conduzindo o atendimento terapêutico, despertando as habilidades
                do terapeuta e discussão de caso
              </dd>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Manipulação das essências Florais
              </dt>
              <dd>
                como são feitos os florais, tintura mãe, solução de estoque e a manipulação das essências em frasco de
                uso durante todo o curso
              </dd>
            </dl>
            <h2>
              Materiais <strong>inclusos</strong>
            </h2>
            <div className={styles.materials}>
              <div className={styles.materialsItem}>
                <div className={styles.materialsImage}>
                  <Image className={styles.shareContentImage} fluid={folder.childImageSharp.fluid} />
                </div>
                <div className={styles.materialDescription}>
                  <h6>Pasta do Curso</h6>
                  <p>Aproximadamente 300 páginas, contendo textos e exercícios do curso</p>
                </div>
              </div>

              <div className={styles.materialsItem}>
                <div className={styles.materialsImage}>
                  <Image className={styles.shareContentImage} fluid={game.childImageSharp.fluid} />
                </div>
                <div className={styles.materialDescription}>
                  <h6>Jogo de 40 cartões</h6>
                  <p>As 38 flores e dois postais mostrando o preparo das essências</p>
                </div>
              </div>

              <div className={styles.materialsItem}>
                <div className={styles.materialsImage}>
                  <Image className={styles.shareContentImage} fluid={floral.childImageSharp.fluid} />
                </div>
                <div className={styles.materialDescription}>
                  <h6>Floral de Bach</h6>
                  <p>1 frasco de uso de florais por aula para vivência prática dos estados emocionais</p>
                </div>
              </div>

              <div className={styles.materialsItem}>
                <div className={styles.materialsImage}>
                  <Image className={styles.shareContentImage} fluid={book.childImageSharp.fluid} />
                </div>
                <div className={styles.materialDescription}>
                  <h6>Livro</h6>
                  <p>O livro Cura-te a Ti Mesmo, do Dr. Edward Bach para mais compreensão</p>
                </div>
              </div>
            </div>
          </InternalContent>
          <div className='aside'>
            <InternalWorkshopNoTab>
              <h3>
                <small>Formação Presencial</small>
                Aprenda a Usar Florais de Bach
              </h3>
              <ul>
                <li>Supervisão clínica</li>
                <li>Ambiente acolhedor</li>
                <li>Amplamente prático e vivêncial</li>
                <li>Material complementar incluso</li>
                <li>Certificado internacional HealingHerbs</li>
                <li>Sem pré-requisitos</li>
                <li>Curso destinado a todos os públicos </li>
                <li>Tire suas dúvidas ao vivo</li>
                <li>Total de 60 horas aula</li>
              </ul>
              <a
                href={WHATSAPPLINK}
                target='_blank'
                rel='noopener noreferrer'
                className={`button button--purpleReverse button internalWorkshop-button`}
              >
                Inscrição Curso <small></small>
              </a>
            </InternalWorkshopNoTab>

            <HealingHerbs />
            <InternalTestimonial className={styles.testimonial} />
          </div>
        </div>
      </section>
    </>
  )
}
